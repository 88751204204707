/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';
// Assets
import localisation from '../../../images/index/Contact/localisation.svg';
import email1 from '../../../images/index/Contact/email.svg';
import phone1 from '../../../images/index/Contact/phone.svg';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function DetailsSection({ email, phoneNumber, description, address }) {
  // localization
  const { t } = useTranslation();
  const about = t('about', { returnObjects: true });

  /* ******************************** RENDERING ******************************* */
  function Description() {
    if (!description) return null;
    return description?.split('<br>').map((paragraph) => <p>{paragraph}</p>);
  }

  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="details-section" style={{ maxWidth: '1200px' }}>
      <Row style={{ margin: '3rem 0' }}>
        <Col xxl={4} xl={4} lg={4} sm={12} xs={12} />
        <Col xxl={8} xl={8} lg={8} sm={12} xs={12}>
          <h1>{about}</h1>
        </Col>
      </Row>
      <Row>
        <Col xxl={4} xl={4} lg={4} sm={12} xs={12}>
          <ul className="details-section-contact">
            <li>
              <img src={localisation} alt="" />
              {address}
            </li>
            <li>
              <img src={email1} alt="" />
              {email}
            </li>
            <li>
              <img src={phone1} alt="" />
              {phoneNumber}
            </li>
          </ul>
        </Col>
        <Col xxl={8} xl={8} lg={8} sm={12} xs={12}>
          <Description />
        </Col>
      </Row>
    </Container>
  );
}

DetailsSection.propTypes = {
  email: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

DetailsSection.defaultProps = {};

export default DetailsSection;
