/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { graphql, navigate } from 'gatsby';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useI18next } from 'gatsby-plugin-react-i18next';
import axios from 'axios';

// Lib UI components

// Local UI components
import PageLayout from '../../shared/PageLayout';
import Seo from '../../shared/Seo/index';
import Hero from '../../page-components/CenterDetails/Hero/index';
import DetailsSection from '../../page-components/CenterDetails/DetailsSection/index';
import Gallery from '../../page-components/CenterDetails/Gallery/index';

import { CentersEndPoint } from '../../shared/APIs';
// Style
import '../../page-styles/CenterDetails.scss';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function CenterDetails({ name }) {
  /* ********************************** HOOKS ********************************* */
  const [center, setCenter] = useState();
  const { language } = useI18next();

  const locale = language === undefined ? 'fr' : language;

  /* ********************************** CALLBACKS ********************************* */

  React.useEffect(() => {
    async function getCenter() {
      try {
        const { data } = await axios(CentersEndPoint(locale, name));
        if (data?.data[0]?.attributes) {
          setCenter(data?.data[0]?.attributes);
        } else {
          navigate('/center');
        }
      } catch {
        navigate('/center');
      }
    }
    getCenter();
  }, [locale, name]);

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title="Center details" />
      <Hero
        imageUrl={center?.hero?.data?.attributes?.url}
        name={center?.name}
        year={center?.year}
      />
      <DetailsSection
        email={center?.Email}
        phoneNumber={center?.PhoneNumber}
        description={center?.Description}
        address={center?.address}
      />
      <Gallery images={center?.gallery?.data} />
    </PageLayout>
  );
}

CenterDetails.propTypes = {
  name: PropTypes.string.isRequired,
};
CenterDetails.defaultProps = {};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["CenterDetails", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default CenterDetails;
