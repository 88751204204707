/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';

// UI lib comoponents

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';
import { baseUploads } from '../../../shared/APIs';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Hero({ imageUrl, name, year }) {
  /* ******************************** RENDERING ******************************* */

  return (
    <div
      className="hero"
      style={{ backgroundImage: `url(${baseUploads}${imageUrl})` }}
    >
      <span>{year}</span>
      <h1>{name}</h1>
    </div>
  );
}

Hero.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
};

Hero.defaultProps = {};

export default Hero;
