/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';

// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';

// Local UI components

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
import { baseUploads } from '../../../shared/APIs';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Gallery({ images }) {
  /* ******************************** CONSTANTS ******************************* */

  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */

  /* ********************************** HOOKS ********************************* */

  // State

  // Other hooks like reux or localization ...

  /* ***************************** LOCAL VARIABLES **************************** */

  /* ******************************** CALLBACKS ******************************* */
  /**
   * Computes the perimeter
   */

  /* ***************************** RENDER HELPERS ***************************** */

  /* ******************************** RENDER HELPERS ******************************* */

  /* ******************************** RENDERING ******************************* */

  return (
    <Container
      className="gallery"
      style={{ maxWidth: '1285px', margin: '0 auto 3rem' }}
    >
      <Row align="start" justify="center">
        <Col align="center" justify="center" lg={6} sm={12}>
          {images?.slice(0, Math.ceil(images?.length / 2))?.map((image) => {
            return (
              <div className="center-img-container">
                <img src={`${baseUploads}${image?.attributes?.url}`} alt="" />
              </div>
            );
          })}
        </Col>
        <Col
          align="center"
          justify="center"
          lg={6}
          sm={12}
          className="center-translate"
        >
          {images
            ?.slice(Math.ceil(images?.length / 2), images?.length)
            ?.map((image) => {
              return (
                <div className="center-img-container">
                  <img src={`${baseUploads}${image?.attributes?.url}`} alt="" />
                </div>
              );
            })}
        </Col>
      </Row>
    </Container>
  );
}

Gallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Gallery.defaultProps = {};

export default Gallery;
